import { Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "../../../redux/typedHooks";
import { msToTime } from "../../../utils/helpers/msToTime";
import { ItemGrid } from "./CustomCard";

const TimeRunning: FC = () => {
  const { startData } = useSelector((store) => store.triggerEvent.data);
  const [Time, setTime] = useState(0);

  useEffect(() => {
    const timeStarted = new Date(startData).getTime();
    const now = new Date().getTime();
    const count = now - timeStarted;

    setTime(count);
  }, [startData]);

  useEffect(() => {
    const inverval = setInterval(() => {
      setTime((prev) => prev + 1000);
    }, 1000);

    return () => {
      clearInterval(inverval);
    };
  }, []);

  return (
    <ItemGrid>
      <Typography variant="h6" align="left" gutterBottom>
        Time running
      </Typography>
      <Typography
        variant="h4"
        align="left"
        gutterBottom
        sx={{ color: "red", fontSize: { xs: "1.25rem", sm: "2rem" } }}
      >
        {msToTime(Time)} hrs
      </Typography>
    </ItemGrid>
  );
};

export default TimeRunning;
