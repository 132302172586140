import React, { FC } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useStylesDataGrid } from "../styles";
import { Paper, Typography, useMediaQuery } from "@mui/material";
import { useSelector } from "../../../redux/typedHooks";
import { icons, IconsType } from "../Icons";

const DataGridLocationInfo: FC = () => {
  const styles = useStylesDataGrid();
  const isMobile = useMediaQuery("(max-width:860px)");
  const { data } = useSelector((store) => store.triggerEvent);

  const columns: GridColDef[] = [
    {
      field: "assignedTeacher",
      headerName: "Name",
    },
    {
      field: "locationName",
      headerName: "Location",
    },
    {
      field: "teacherRole",
      headerName: "Role",
      type: "number",
    },
    {
      field: "headCount",
      headerName: "Head Count",
      type: "number",
    },
    {
      field: "missingCount",
      headerName: "Missing",
      type: "number",
    },
    {
      field: "additionalCount",
      headerName: "Additional",
      type: "number",
    },
    {
      field: "status",
      headerName: "Injured",
      renderCell: ({ row }: any) => {
        const status = row.status.toLocaleLowerCase() as IconsType;
        return icons[status];
      },
    },
  ];

  const formattedColumns = columns.map((i) =>
    isMobile ? { ...i, width: 100 } : { ...i, flex: 1 },
  );

  return (
    <Paper sx={{ color: "#4d4d4d" }}>
      <Typography variant="h6" sx={{ padding: 1.5 }}>
        Location Info
      </Typography>
      <Box sx={{ height: 300, width: "100%" }}>
        <DataGrid
          className={styles.root}
          rows={data.locations}
          columns={formattedColumns}
          pageSize={5}
          getRowId={(row) => row.id}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </Paper>
  );
};

export default DataGridLocationInfo;
