// ---Dependencies
import React, { useEffect, useRef, useState } from "react";
// ---UI Dependencies
import { Box, MenuItem, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
// ---Config
// ---Types
import {
  IHeadCountFormProps,
  IHeadCountProps,
  ISubFormHeadCount,
} from "./interfaces";
import DataGridHeadCount from "./components/DataGrid";
import { useSelector } from "../../redux/typedHooks";
import { fetchLocationsQuery } from "../../services/locations";
import { INJURED_STATUS } from "../../constants";
import SubFormHeadCount from "./components/SubFormHeadCount";
import { styles } from "./styles";
import { getEventLocationData } from "../../services/eventInProgress";
import { openModal, PayloadOpenModal } from "../../redux/actions/modal";
import { useDispatch } from "react-redux";

/**
 * HeadCount Component: Component that contain the form input fields
 * to register a new school
 * @param {IHeadCountProps} props
 * @returns {React.ReactElement} React.ReactElement
 */
const HeadCount: React.FC<IHeadCountFormProps> = ({
  formik,
  hasInitialValues,
  resetForm,
}) => {
  const dispatch = useDispatch();
  const [Locations, setLocations] = useState<any[]>([]);
  const [originalForm, setOriginalForm] = useState(formik.values);
  const [isGettingData, setIsGettingData] = useState(false);
  const locationRef = useRef<HTMLInputElement>(null);
  const headCountRef = useRef<HTMLInputElement>(null);
  const missingCountRef = useRef<HTMLInputElement>(null);
  const additionalCountRef = useRef<HTMLInputElement>(null);
  const injuredRef = useRef<HTMLInputElement>(null);
  // -----------------------CONSTS, HOOKS, STATES
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  useEffect(() => {
    formik.setFieldValue("school", user.school._id);
    formik.setFieldValue("assignedTeacher", user._id);
  }, [user.school._id]);

  useEffect(() => {
    fetchLocationsQuery(`?school=${user.school._id}`).then(({ data }) => {
      setLocations(data);
    });
  }, [user.school._id]);

  useEffect(() => {
    setOriginalForm(formik.values);
  }, [hasInitialValues]);

  useEffect(() => {
    if (isGettingData) {
      setOriginalForm(formik.values);
    }
  }, [formik.values.locationId]);

  useEffect(() => {
    if (formik.errors.locationId && formik.touched.locationId && locationRef.current) {
      locationRef.current.focus();
    } else if (formik.errors.headCount && formik.touched.headCount && headCountRef.current) {
      headCountRef.current.focus();
    } else if (formik.errors.missingCount && formik.touched.missingCount && missingCountRef.current) {
      missingCountRef.current.focus();
    } else if (formik.errors.additionalCount && formik.touched.additionalCount && additionalCountRef.current) {
      additionalCountRef.current.focus();
    } else if (formik.errors.status && formik.touched.status && injuredRef.current) {
      injuredRef.current.focus();
    }
  }, [formik.errors, formik.touched]);

  useEffect(() => {
    if (validateChanges()) {
      const modalConfig: PayloadOpenModal = {
        title: "Alert!",
        description: `You have changes unsaved, continue without saving?`,
        action: () => {
          setFormValues(originalForm);
        },
        primaryButtonText: "continue",
      };
      dispatch(openModal(modalConfig));
    }
  }, [resetForm]);

  // --- MAIN FUNCTIONS
  function handleAddStudent(value: ISubFormHeadCount) {
    const { status, name } = value;

    if (status === "additional") {
      formik.setFieldValue("additionalStudents", [
        ...formik.values.additionalStudents,
        name,
      ]);
    }
    if (status === "missing") {
      formik.setFieldValue("missingStudents", [
        ...formik.values.missingStudents,
        name,
      ]);
    }
  }

  function handleRemoveStudent(value: ISubFormHeadCount) {
    const { status, name } = value;
    if (status === "additional") {
      const newArray = [...formik.values.additionalStudents].filter(
        (i) => i !== name,
      );
      formik.setFieldValue("additionalStudents", newArray);
    }
    if (status === "missing") {
      const newArray = [...formik.values.missingStudents].filter(
        (i) => i !== name,
      );
      formik.setFieldValue("missingStudents", newArray);
    }
  }

  function validateChanges() {
    let haveChanges = false;
    for (const key in originalForm) {
      if (
        originalForm[key as keyof IHeadCountProps] !==
          formik.values[key as keyof IHeadCountProps] &&
        key !== "school" &&
        key !== "assignedTeacher"
      ) {
        haveChanges = true;
      }
    }
    return haveChanges;
  }

  async function handleValidateChanges(event: React.ChangeEvent<any>) {
    if (validateChanges()) {
      const modalConfig: PayloadOpenModal = {
        title: "Alert!",
        description: `You have changes unsaved, continue without saving?`,
        action: () => {
          changeLocation(event);
        },
        primaryButtonText: "continue",
      };
      dispatch(openModal(modalConfig));
    } else {
      changeLocation(event);
    }
  }

  async function changeLocation(event: React.ChangeEvent<any>) {
    setIsGettingData(true);
    getEventLocationData(event.target.value, user.school._id)
      .then(({ data }) => setFormValues(data))
      .catch(() => {
        clearForm();
      })
      .finally(() => {
        formik.handleChange("locationId")(event);
        setIsGettingData(false);
      });
  }

  function setFormValues(data: any) {
    formik.setFieldValue("missingCount", data.missingCount);
    formik.setFieldValue("additionalCount", data.additionalCount);
    formik.setFieldValue("headCount", data.headCount);
    formik.setFieldValue("status", data.status);
    formik.setFieldValue("additionalStudents", data.additionalStudents);
    formik.setFieldValue("missingStudents", data.missingStudents);
    formik.setFieldValue("notes", data.notes);
  }

  const clearForm = () => {
    formik.handleReset();
    formik.setFieldValue("school", user.school._id);
    formik.setFieldValue("assignedTeacher", user._id);
  };

  // -----------------------RENDER
  return (
    <Box sx={styles.box}>
      <Autocomplete
      fullWidth
      id="location-autocomplete"
      disablePortal
      getOptionLabel={(option) => option.name} 
      options={Locations}
      value={Locations.find(loc => loc._id === formik.values.locationId) || null}
      onChange={(event, newValue) => {
        formik.setFieldValue("locationId", newValue?._id || "");
      }}
      renderInput={(params) => (
        <TextField

          {...params}
          label="Location*"
          variant="outlined"
          error={!!formik.errors.locationId && !!formik.touched.locationId}
          helperText={
            !!formik.errors.locationId &&
            !!formik.touched.locationId &&
            formik.errors.locationId
          }
          inputRef={locationRef}
        />
      )}
      isOptionEqualToValue={(option, value) => option._id === value._id}
    />
      <TextField
        label="Head Count*"
        type="number"
        variant="outlined"
        value={formik.values.headCount}
        error={!!formik.errors.headCount && !!formik.touched.headCount}
        helperText={
          !!formik.errors.headCount &&
          !!formik.touched.headCount &&
          formik.errors.headCount
        }
        onChange={formik.handleChange("headCount")}
        inputRef={headCountRef}
      />
      <TextField
        label="Missing Count*"
        type="number"
        variant="outlined"
        value={formik.values.missingCount}
        error={!!formik.errors.missingCount && !!formik.touched.missingCount}
        helperText={
          !!formik.errors.missingCount &&
          !!formik.touched.missingCount &&
          formik.errors.missingCount
        }
        onChange={formik.handleChange("missingCount")}
        inputRef={missingCountRef}
      />
      <TextField
        label="Additional Count*"
        type="number"
        variant="outlined"
        value={formik.values.additionalCount}
        error={
          !!formik.errors.additionalCount && !!formik.touched.additionalCount
        }
        helperText={
          !!formik.errors.additionalCount &&
          !!formik.touched.additionalCount &&
          formik.errors.additionalCount
        }
        onChange={formik.handleChange("additionalCount")}
        inputRef={additionalCountRef}

      />

      {/*       <TextField
        label="Notes*"
        variant="outlined"
        multiline
        rows={3}
        value={formik.values.notes}
        error={!!formik.errors.notes && !!formik.touched.notes}
        helperText={
          !!formik.errors.notes && !!formik.touched.notes && formik.errors.notes
        }
        onChange={formik.handleChange("notes")}
      /> */}
      <TextField
        select
        label="Injured*"
        variant="outlined"
        value={formik.values.status ?? INJURED_STATUS[0].value}
        error={!!formik.errors.status && !!formik.touched.status}
        helperText={
          !!formik.errors.status &&
          !!formik.touched.status &&
          formik.errors.status
        }
        onChange={formik.handleChange("status")}
        inputRef={injuredRef}

      >
        {INJURED_STATUS.map((i, index) => (
          <MenuItem key={index} value={i.value}>
            {i.label}
          </MenuItem>
        ))}
      </TextField>
      <SubFormHeadCount onSubmit={handleAddStudent} />
      <DataGridHeadCount
        handleDelete={handleRemoveStudent}
        additional={formik.values.additionalStudents}
        missing={formik.values.missingStudents}
      />
    </Box>
  );
};

export default HeadCount;
