import CheckIcon from "@mui/icons-material/Check";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { IconButton, Tooltip } from "@mui/material";
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

export type IconsType = "clear" | "injured" | "unreported";

const withTooltip = (icon: any, text: string) => (
  <Tooltip title={text}>
    <IconButton>{icon}</IconButton>
  </Tooltip>
);

export const icons = {
  clear: withTooltip(<CheckIcon sx={{ color: "green" }} />, "Clear"),
  injured: withTooltip(<MedicalServicesIcon sx={{ color: "red" }} />, "Injured"),
  unreported: withTooltip(
    <SyncProblemIcon sx={{ color: "darkgray" }} />,
    "Unreported",
  ),
};
