import { makeStyles, createStyles } from "@mui/styles";
import { createTheme } from "@mui/material";

const defaultTheme = createTheme();

export const useStylesDataGrid = makeStyles(
  (theme) =>
    createStyles({
      root: {
        backgroundColor: "white",
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#E2E2E280",
        },
        border: "none",
        "& .MuiDataGrid-row": {
          backgroundColor: "",
        },
      },
    }),
  { defaultTheme },
);

export const styles = {
  gridLayout: {
    padding: {
      sm: "20px 48px",
      xs: "20px 20px",
    },
    marginTop: "8.5rem",
  },
  gridMobile: {
    padding: {
      sm: "20px 48px",
      xs: "20px 20px",
    },
  },
  buttonStopEventContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: 2,
    marginTop:'12px',
  },
};
