import React, { useState } from "react";
// --- UI DEPENDENCIES
import CloseIcon from "@mui/icons-material/Close";
import { Box, Fab, Grid, Tab, Tabs, Toolbar } from "@mui/material";
// --- COMPONENTS
import DataGridAdditionalPeople from "../components/DataGridAdditionalPeople";
import DataGridLocationInfo from "../components/DataGridLocationInfo";
import DataGridMissingPeople from "../components/DataGridMissingPeople";
import EventGeneralInformation from "../components/EventGeneralInformation";
import HeadCountInfo from "../components/HeadCountInfo";
import TabPane from "../components/TabPane";
import TimeElapsed from "../components/TimeElapsed";
import { styles } from "../styles";
import StopEventDialog from "../components/StopEventDialog";
import { useSelector } from "../../../redux/typedHooks";
import CommetChat from "../../../components/CommetChat";

const Mobile: React.FC<{
  handleStop: (arg0: number | undefined) => () => void;
}> = ({ handleStop }) => {
  const [tab, setTab] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { triggerEvent } = useSelector((store) => store);

  function handleChange(_: React.SyntheticEvent, newValue: number) {
    setTab(newValue);
  }
  const handleCloseModal = () => setShowConfirmation(false);

  return (
    <>
      <Box sx={{ height: `calc(100vh - ${64}px)`, marginTop: "70px" }}>
        <Toolbar />
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "relative",
            }}
          >
            <Fab
              onClick={() => setShowConfirmation(true)}
              sx={{
                backgroundColor: "red",
                color: "#fff",
                position: "absolute",
                bottom: -30,
                right: 30,
                zIndex: 10,
              }}
            >
              <CloseIcon />
            </Fab>
            <Tabs value={tab} onChange={handleChange}>
              <Tab label="General" />
              <Tab label="Students List" />
              <Tab label="Chat" />
            </Tabs>
          </Box>
          <Box sx={{ ...styles.gridMobile }}>
            <TabPane value={tab} index={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TimeElapsed />
                </Grid>
                <Grid item xs={12}>
                  <EventGeneralInformation />
                </Grid>
                <Grid item xs={12}>
                  <HeadCountInfo />
                </Grid>
                <Grid item xs={12}>
                  <DataGridLocationInfo />
                </Grid>
              </Grid>
            </TabPane>
            <TabPane value={tab} index={1}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DataGridMissingPeople />
                </Grid>
                <Grid item xs={12}>
                  <DataGridAdditionalPeople />
                </Grid>
              </Grid>
            </TabPane>
            <TabPane value={tab} index={2}>
              <Box sx={{ height: "calc(100vh - 250px)" }}>
                <CommetChat />
              </Box>
            </TabPane>
          </Box>
          {showConfirmation && (
            <StopEventDialog
              open={true}
              title={`Would you like to FINISH the current event?`}
              cancelButtonAction={handleCloseModal}
              confirmButtonAction={handleStop}
              originalHeadCount={triggerEvent.data.expectedHeadCount}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default Mobile;
