import { Box, Toolbar, Typography } from "@mui/material";
import React from "react";
import PageTitle from "../../../components/DashboardLayout/PageTitle";
import PanicButton from "../../../components/PanicButton/PanicButton";

const WithOutEvent = () => {
  return (
    <>
      <Box sx={{ height: `calc(100vh - ${64}px)`, marginTop: "10px" }}>
        <Toolbar />
        <Box
          sx={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}
        >
          <PanicButton
            name="addAlert"
            iconPosition="start"
            label="Alert"
            variant="contained"
            color="error"
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90%",
          }}
        >
          <Typography textAlign={'center'} variant="h4">No Events Running</Typography>
        </Box>
      </Box>
    </>
  );
};

export default WithOutEvent;
