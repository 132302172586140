interface IStringKey {
  [key: string]: string;
}

export const DATE_FORMAT = "DD/MM/YYYY";
export const PAYROLL_ATTENDANCE_DATE_FORMAT = "YYYY-MM-DD";

export const DRIVER_TYPES = {
  payroll: "nomina",
  route: "ruta",
};

export const DRIVER_TRANSLATION: IStringKey = {
  nomina: "Nómina",
  ruta: "Ruta",
};

export const USER_TYPES: IStringKey = {
  partner: "Socio",
  driver: "Empleado",
};

export const USER_ROLES: IStringKey = {
  RL000: "admin",
  RL001: "coordinator",
  RL005: "enrollment",
};

export const ATTENDANCE_CALENDAR_OPTIONS = {
  ASISTENCIA: { label: "asistencia", color: "#1bc943" },
  FALTA: { label: "falta", color: "red" },
  DESCANSO: { label: "descanso", color: "yellow" },
};

export const DOWNLOAD_TYPES: IStringKey = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const ROUTE_STATUS: IStringKey = {
  ACTIVE: "activa",
  CANCELED: "cancelada",
};

export const GRADE_LEVELS_OPTIONS = [
  "Nursery",
  "Reception",
  "Preschool",
  "Kindergarten",
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
  "8th Grade",
  "9th Grade",
  "10th Grade",
  "11th Grade",
  "12th Grade",
];

export const DISTRICT_OPTIONS = [
  {
    name: "DISTRTICT 1",
    value: "DISTRTICT 1",
  },
  {
    name: "DISTRTICT 2",
    value: "DISTRTICT 2",
  },
  {
    name: "DISTRTICT 3",
    value: "DISTRTICT 3",
  },
];

export const DISTRICT_OPTIONS_FILTER = [
  {
    name: "ALL DISTRICTS",
    value: "ALL DISTRICTS",
  },
  ...DISTRICT_OPTIONS,
];

export const STATES_USA = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const STATES_USA_FILTER = ["ALL STATES", ...STATES_USA];

export const LOCATION_TYPES_INDOOR = [
  "Classroom",
  "Gym",
  "Cafeteria",
  "Library",
  "Offices",
  "Auditorium",
];
export const LOCATION_TYPES_OUTDOOR = [
  "Zone 1",
  "Zone 2",
  "Zone 3",
  "Reunification Location",
  "Route to reunification location",
];

interface roleFormat {
  [key: string]: string;
}

export const roles: roleFormat = {
  SUPERADMIN: "RL000",
  DISTRICT_ADMIN: "RL001",
  SAFETY_COMMANDER: "RL002",
  SAFETY_TEAM: "RL003",
  TEACHER: "RL004",
  ENROLLMENT_LEADER: "RL005",
};

export const rolesNames: roleFormat = {
  RL000: "Super Admin",
  RL001: "District Admin",
  RL002: "Safety Commander",
  RL003: "Safety Team",
  RL004: "Teacher/Staff Member",
  RL005: "Enrollment Leader",
}

export const schoolRoles = {
  SAFETY_COMMANDER: "RL002",
  SAFETY_TEAM: "RL003",
  TEACHER: "RL004",
  ENROLLMENT_LEADER: "RL005",
};

export const schoolRolesNames: roleFormat = {
  RL002: "Safety Commander",
  RL003: "Safety Team",
  RL004: "Teacher/Staff Member",
  RL005: "Enrollment Leader",
}

export const INJURED_STATUS = [
  {label:"Yes",value:"Injured"},
  {label:"No",value:"Clear"}
];

export const EVENT_EMERGENCY_TYPES = [
  "drill",
  "emergency",
]

export const UNIQUE_LISTENER_ID = "COMMET_CHAT_LISTENER";
